  var Backbone = require('backbone'),
      _ = require('underscore');

  var baseView = {
    addEvents: function(events) {
      this.delegateEvents(_.extend(_.clone(this.events), events));
    }
  };

  module.exports = baseView;
