  var Backbone = require('backbone'),
      _ = require('underscore'),
      baseCollection = require('./base.js'),
      metric = require('../models/metric.js');

  var metricsProto = _.assign({
    model: metric,

    url: function() { return this.urlRoot + '/metrics'; },

    initialize: function(models, options) {
      options || (options = {});
    }

  }, baseCollection);

  var metrics = Backbone.Collection.extend(metricsProto);

  module.exports = metrics;
