  var Backbone = require('backbone'),
      _ = require('underscore'),
      baseModel = require('./base.js');

  var reservationProto = _.assign({
    url: function() { 
      var base = this.urlRoot + '/reservations';
      if (this.id) {
        base += '/' + this.id;
      } 
      return base;
    },

    init: function(attributes, options) {
      options || (options = {});
    }
    
  }, baseModel);

  var reservation = Backbone.Model.extend(reservationProto);

  module.exports = reservation;
