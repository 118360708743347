  var Backbone = require('backbone'),
      _ = require('underscore'),
      appConfig = require('../config/config.js'),
      utilities = require('../lib/utilities.js'),
      appEvents = require('../appEvents.js');
  
  var baseModel = {
    urlRoot: appConfig.apiUrl, 
 
    initialize: function(attributes, options) {
      this.on("error", this.handleError);
      this.init(attributes, options); 
    },

    handleError: function(obj, xhr, options) {
      // If they get a 403 - logout for now
      if (xhr && xhr.status === 403) {
        appEvents.trigger("unAuthorized");        
      }
    },

    getHeaders: function() { 
      return { "x-access-token": appConfig.token };
    },

    destroy: function(options) {
      options || (options = {});
      options.headers = this.getHeaders();
      return Backbone.Model.prototype.destroy.call(this, options);
    }, 
 
    fetch: function(options) {
      options || (options = {});
      options.headers = this.getHeaders();
      options.url = utilities.addToQueryString(_.result(this,'url'), options.query);
      return Backbone.Model.prototype.fetch.call(this, options);
    },

    save: function(attributes, options) {
      options || (options = {});
      options.headers = this.getHeaders();
      return Backbone.Model.prototype.save.call(this, attributes, options);
    }

  };
  
  module.exports = baseModel;
