    var Backbone = require('backbone'),
        appRouter = require('./router.js'),
        appEvents = require('./appEvents.js'),
        appEventHandler = require('./appEventHandler.js');

  var app = function() {
  
    // Create our Application router
      var router = new appRouter();
          
      // Initialize Event Handler with Event Source and Router
      appEventHandler.initialize(appEvents, router);
      
      // Check if we're redirecting for login
      var hash = window.location.hash;
      if (hash) {
        appEvents.trigger("redirected", { hash: hash, noLogin: false });
        window.location.hash=''; 
      }
 
      // Start Backbone History
      Backbone.history.start();

  	  appEvents.trigger("start");
    };    

    module.exports = app;  
