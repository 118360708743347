  var Backbone = require('backbone'),
      _ = require('underscore'),
      $ = require('jquery'),
      appConfig = require('../config/config.js'),
      utilities = require('../lib/utilities.js');

  var base = {
    urlRoot: appConfig.apiUrl,
    
    fetch: function(options) {
      var options = options || {};
      options.headers = this.getHeaders();
      options.url = _.result(this, 'url');
      if (options.addParam) {
        options.url += '/' + options.addParam;
      }
      options.url = utilities.addToQueryString(options.url, options.query);
      return Backbone.Collection.prototype.fetch.call(this, options);
    },
    
    getHeaders: function() { 
      return { "x-access-token": appConfig.token };
    }
  };

  module.exports = base;
