  var appUser = require('../models/appUser.js'),
      appEvents = require('../appEvents.js'),
      appViews = require('../appViews.js'),
      adminHome = require('../views/adminHome'),
      supportHome = require('../views/supportHome'),
      Modernizr = require('../lib/modernizr.js'),
      toastr = require('toastr'),
      appView = require('../views/app.js'),
      AppUserView = require('../views/appUser.js'),
      AccountsView = require('../views/accounts.js'),
      ReportView = require('../views/report.js'),
      loginView = require('../views/login.js');
 
  var appController = {
    start: function() {
        new appView(); 
    },
    showHome: function() {
      var role = appUser.get("role");
      if (role==='admin' || role==='viewer') {
        new adminHome();
      }else if (role==='support') {
        new supportHome();
      }
    },
    login: function() {
        new loginView();
    },
    logout: function() {
      appUser.logout(function() {
        if (Modernizr.localstorage) {
          localStorage.removeItem("user");
        }
        appUser.clear({silent: true});
        appEvents.trigger("loggedOut");
      },
      function() {
        toastr.error("There was an error logging out.");
      });
    },
    resetCredentials: function() {
      if (Modernizr.localstorage) {
          localStorage.removeItem("user");
      }
      appUser.clear({silent: true});
      appEvents.trigger("loggedOut");
    },
    search: function(searchValue) {
    	new AccountsView({ search: searchValue });
    },
    showAppUser: function() {
      new AppUserView();
    },
    showReport: function(reportId) {
      new ReportView({ reportId: reportId });
    }
  };

  module.exports = appController;
