  module.exports = {
    views: [],
    autoDispose: function(options) {
      options || (options = {});
      for (var i = this.views.length-1; i >= 0; i--) {
        var element = this.views[i];
        if (options.all || element.autoDispose) {
          element.view.dispose();
          // We only remove the truly disposeable views
          // from the views array
          if (element.autoDispose) this.views.splice(i,1);
        }
      }
    },
    add: function(view, autoDispose) {
      this.views.push({ view: view, autoDispose: autoDispose });
    }
  };
