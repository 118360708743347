	'use strict';
    var Backbone = require('backbone'),
        _ = require('underscore'),
        controllers = require('./controllers/index.js'),
        appViews = require('./appViews.js');

	var Router = Backbone.Router.extend({
	    execute: function(callback, args, name) {
	      var self = this;
          var hasDirtyView = _.some(appViews.views, function(element) {
            if (element.view.dirty) {
              var navigationCallback = function() {
                  element.view.dirty = false;
                  callback.apply(self, args);
              };
              // The null part refers to the "e" or element that the beforeUnload function will also reference
              var confirm = element.view.beforeUnload.bind(element.view, null, navigationCallback);
              confirm();
              self.navigate(element.view.dirtyHash);
              return true;
            }
          });
          if (hasDirtyView) {
            return false;
          }

          appViews.autoDispose();
          if (callback) callback.apply(self, args);
        },
        routes: {
			'login': 'login',
			'app': 'app',
			'appUser': 'appUser',
            'accounts(/:code)': 'accounts',
            'accounts(/:filter)/filter': 'accountsFiltered',
            'reservations': 'reservations',
            'logout': 'logout',
            'search(/:search)': 'search',
            'reports/:reportId': 'report'
		},
		accountsFiltered: function(filter) {
          this.navigate("accounts/" + filter + "/filter");
          controllers.account.showFilteredAccounts(filter);
		},
        accounts: function(code) {
          if (code) {
            console.log("Router: Account Id " + code + " requested");
            this.navigate("accounts/" + code);
            controllers.account.show(code);
          }else{
            console.log("Router: Accounts requested");
            this.navigate("accounts");
            controllers.account.showAll();
          }
        },
        reservations: function() {
          console.log("Router: Reservations requested");
          this.navigate("reservations");
          controllers.reservation.showAll();
        },
		app: function() {
          console.log("Router: App screen requested.");
          this.navigate("app");
          controllers.app.showHome();
		},
		appUser: function() {
		  console.log("Router: App User Profile requested");
		  this.navigate("appUser");
          controllers.app.showAppUser();
		},
        login: function() {
          console.log("Router: Login screen requested.");
          this.navigate("login");
          appViews.autoDispose({ all: true });
          controllers.app.login();
        },
        logout: function() {
          console.log("Router: logout requested.");
          controllers.app.logout();
        },
        search: function(search) {
          console.log("Searching for: " + search);
          this.navigate("search/" + search);
          controllers.app.search(search);
        },
        report: function(reportId) {
          console.log("Report: " + reportId);
          this.navigate("reports/" + reportId);
          controllers.app.showReport(reportId);
        }
	});

	module.exports = Router;
