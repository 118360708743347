  var appEvents = require('../appEvents.js'),
      appUser = require('../models/appUser.js'),
      ReservationsView = require('../views/reservations.js');

  var ReservationController = {
    showAll: function() {
      new ReservationsView();
    }
  };

  module.exports = ReservationController;
