/* Replaced Modernizr code with our own for two reasons:

  *  didn't really play well with Browserify
  *  we were only using localStorage checks

  TODO: Rename this module at some point

*/
var Modernizr = {

  localstorage: function() {
    var mod = 'modernizr';
    try {
      localStorage.setItem(mod, mod);
      localStorage.removeItem(mod);
      return true;
    } catch (e) {
      return false;
    }
  }
};

module.exports = Modernizr;
