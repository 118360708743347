  var Backbone = require('backbone'),
      _ = require('underscore'),
      baseCollection = require('./base.js'),
      account = require('../models/account.js');

  var accountsProto = _.assign({
    model: account,

    url: function() { return this.urlRoot + '/accounts'; },

    initialize: function(models, options) {
      options || (options = {});
    }

  }, baseCollection);

  var Accounts = Backbone.Collection.extend(accountsProto);

  module.exports = Accounts;
