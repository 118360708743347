  var eventHandler = require('./lib/eventHandler.js'),
      controllers = require('./controllers/index.js'),
      appViews = require('./appViews.js'),
      appUser = require('./models/appUser.js'),
      _ = require('underscore');

  var appEventHandler = new eventHandler({
    events: {
	  "start": "start",
	  "home": "home",
	  "loggedOut": "loggedOut",
	  "loggedIn": "loggedIn",
      "redirected": "redirected",
      "appLoaded": "appLoaded",
      "userFailed": "resetCredentials",
      "unAuthorized": "resetCredentials",
      "search": "search"
	},
	start: function() {
      console.log("Handler: App Started");
      this.navigate("app");
      controllers.app.start();
	},
	home: function() {		
	  console.log("Handler: App Home Requested");		
	  this.navigate("app");		
	  controllers.app.showHome();		
	},
	loggedOut: function() {
      if (this.redirection && this.redirection.noLogin) {
        this.redirect();
        return;
      }
      console.log("Handler: Logged Out");
      appViews.autoDispose({ all: true });
      this.navigate("login");
      controllers.app.login();
	},
    loggedIn: function() {
      console.log("Handler: User logged In");
      if(!this.redirect()) { 
        this.navigate("app");
        controllers.app.showHome();
      }
    },
	appLoaded: function() {
      console.log("Handler: Main Application Loaded");      
    },
    redirected: function(redirection) {
      redirection.hash = redirection.hash.replace('#','');
      this.redirection = redirection;
    },
    redirect: function() {
      var redirection = _.clone(this.redirection);
      if (redirection && redirection.hash) {
        this.redirection = undefined;
        this.navigate(redirection.hash, { trigger: true });
        return true;
      }
      return false;
    },
    resetCredentials: function() {
      controllers.app.resetCredentials();
    },
    search: function(searchValue) {
      this.navigate("search/" + searchValue);
      controllers.app.search(searchValue);
    }
  });

  module.exports = appEventHandler;
