  var appEvents = require('../appEvents.js'),
      appUser = require('../models/appUser.js'),
      AccountView = require('../views/account.js'),
      AccountsView = require('../views/accounts.js');

  var AccountController = {
    show: function(code) {
      new AccountView({ code: code });
    },
    showAll: function() {
      new AccountsView();
    },
    showFilteredAccounts: function(filter) {
      var filter;

      switch (filter) {
        case "activeSubscribers":    
          filter = { type: 'subscriber', status: 'active' };
          break;
        case "trialSubscribers":
          filter = { type: 'subscriber', status: 'trial' };
          break;
        case "allSubscribers":
          filter = { type: 'subscriber' };
          break;
        case "deletedSubscribers":
          filter = { type: 'subscriber', status: 'deleted' };
          break;
        case "deactivatedSubscribers":
          filter = { type: 'subscriber', status: 'deactivated' };
          break;
        case "allLegacy":
          filter = { type: 'legacy' };
          break;
        case "activeLegacy":
          filter = { type: 'legacy', status: 'active' };
          break;
        case "deactivatedLegacy":
          filter = { type: 'legacy', status: 'deactivated' };
          break;        
        case "deletedLegacy":
          filter = { type: 'legacy', status: 'deleted' };
          break;        
        case "allInternal":
          filter = { type: 'internal' };
        case "activeInternal":
          filter = { type: 'internal', status: 'active' };
          break;        
        case "deactivatedInternal":
          filter = { type: 'internal', status: 'deactivated' };
          break;
        case "deletedInternal":
          filter = { type: 'internal', status: 'deleted' };
          break;
        default:
          break;
      }
      if (filter) {
        new AccountsView({ filter: filter });
      }else{
        new AccountsView();
      }
    },
  };

  module.exports = AccountController;
