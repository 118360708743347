  var Backbone = require('backbone'),
      _ = require('underscore'),
      baseCollection = require('./base.js'),
      reservation = require('../models/reservation.js');

  var reservationsProto = _.assign({
    model: reservation,

    url: function() { return this.urlRoot + '/reservations'; },

    initialize: function(models, options) {
      options || (options = {});
    }

  }, baseCollection);

  var reservations = Backbone.Collection.extend(reservationsProto);

  module.exports = reservations;
