/* Save this as config.js in same directory, with appropriate values

*/
  appConfig = {
    apiUrl: "https://ops.topmail.com/v1",
    token: "",
    DateFormat: 'ddd, DD MMM YYYY HH:mm',
    ShortDateFormat: 'ddd, DD MMM HH:mm',
    NumberFormat: '(0,0)'
  };
  module.exports = appConfig;
