  var _ = require('underscore'),
      moment = require('moment'),
      numeral = require('numeral'),
      appConfig = require('../config/config.js');

  var viewHelpers = {
    fromCents: function(cents) {
      if (cents!==cents) return cents;
      return cents/100;
    },
    toCents: function(number) {
      if (number!==number) return number;
      return number*100;
    },
    humanDate: function(date) {
      if (date && moment(date).isValid()) {
        return moment(date).format(appConfig.DateFormat); 
      }else{
        return "";
      }
    },
    toHumanDate: function(date) {
      return this.humanDate(date);
    },
    toShortHumanDate: function(date) {
      if (date && moment(date).isValid()) {
        return moment(date).format(appConfig.ShortDateFormat); 
      }else{
        return "";
      }
    },
    identity: function(firstName, lastName, email) {
      return firstName ? (lastName ? firstName + ' ' + lastName : firstName) : email.substring(0, email.indexOf('@')+1); 
    },
    numberDisplay: function(number) {
      return numeral(number).format(appConfig.NumberFormat); 
    },
	toMegabytes: function(kilobytes) {
      if (!kilobytes) return 0;
      return kilobytes/1024;
	},
	toHumanCase: function(word) {
      if (!word || word.length < 1) return;
      return word.substring(0,1).toUpperCase() + word.substring(1); 
	},
	toPrettyJSON: function(object) {
      var prettyString = JSON.stringify(object, null, 4);
      prettyString = prettyString.replace(/ /g, "&nbsp;");
      return prettyString.replace(/\n/g,"<br>");
	}
  };

  module.exports = viewHelpers;
