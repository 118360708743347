  "use strict";
  
  var $ = require('jquery'),
      _ = require('underscore'),
      Promise = require('bluebird'),
      moment = require('moment'),
      momentRange = require('moment-range');

  var Utilities = {
	
    /*
      copies text to the clipboard
   */ 
	copyToClipboard(str) {
 		const el = document.createElement('textarea');
  		el.value = str;
  		el.setAttribute('readonly', '');
  		el.style.position = 'absolute';
  		el.style.left = '-9999px';
 		document.body.appendChild(el);
  		el.select();
  		document.execCommand('copy');
  		document.body.removeChild(el);
	},

    /* Does string comparison

    */
    strcmp: function(a, b)
    {   
        return (a<b?-1:(a>b?1:0));  
    },
    /* Merges an Array in place
 
       If b is undefined, null, falsy nothing will happen

       WARNING: This is by reference. So no return value.
       the object passed as the first parameter is modified
    */
    mergeArray: function(a,b) {
      if (b) {
        Array.prototype.push.apply(a, b); 
      }
    },
    /* Moves element at old_index to position at new_index
       And reorders/indexes Array. 

       Warning: mutative
    */
    moveArrayElement: function (arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length;
        while ((k--) + 1) {
            arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    },
    // TODO: This probably isn't that safe
    // It adds the key/values under query to
    // the querystring
    addToQueryString: function(url, params) {
      if (params) {
        params = _.pick(params, function(value, key, object) {
          return value !== undefined;
        });

        var base = url.split('?')[0],
        qstring = (url.split('?')[1] || "");
        if (qstring) qstring += "&";
        url = base + "?" + qstring + $.param(params);
      }
      return url;
    },
    /* Clones an object

    */
    clone: function(obj) {
      var target = {};
      for (var i in obj) {
        if (obj.hasOwnProperty(i)) {
          target[i] = obj[i];
        }
      }
      return target;
    },
    isInteger: function(val) {
      return (typeof val==='number' && (val%1)===0);
    },
    escapeRegex: function(s) {
      return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    },
    randomString: function(length) {
      var text = "";
      var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for( var i=0; i < length; i++ )
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text; 
    },
     // Thanks: http://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
    isElementInViewport: function(el) {

      //special bonus for those using jQuery
      if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
      }

      var rect = el.getBoundingClientRect();

      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
      );
    },
    /* Takes an array of stylesheets ( [ { 'id':'thingy', 'url':'css/thingy.css' ... */
    addExternalCSS: function(stylesheets, done) {
      var deferreds = _.map(stylesheets, function(stylesheet) {
          return $.get(stylesheet.url, function(css) {
             $('<style id="' + stylesheet.id  + '" type="text/css"></style>').html(css).appendTo("head");
          });
        });

      $.when.apply($, deferreds).then(function() {
        done();
      });
    },
    removeExternalCSS: function(stylesheets) {
      _.each(stylesheets, function(stylesheet) {
        $("#" + stylesheet.id).remove();
      });
    }
  };

  module.exports = Utilities;
