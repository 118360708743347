  var Backbone = require('backbone'),
      _ = require('underscore'),
      $ = require('jquery'),
      fs = require('fs'),
      supportTemplate = fs.readFileSync(__dirname + '/../../templates/supportHome.html','utf8'),
      appViews = require('../appViews.js'),
      appUser = require('../models/appUser.js'),
      baseView = require('./base.js'),
      helpers = require('../lib/viewHelpers.js'),
      validator = require('validator'),
      toastr = require('toastr'),
      Utilities = require('../lib/utilities.js');

  var supportViewProto = _.assign({
   
   el: "#topmail-page-content", 
 
    events: {
    },

    initialize: function(options) {
      options || (options || {});
      appViews.add(this, true);
      this.render();
    },

    render: function(support) {
      var base = supportTemplate;
      var template = _.template(base);
      this.$el.html(template());
    },
    
    dispose: function() {
      $(this.el).empty();
      $(this.el).off();
    }
  
  }, baseView);

  var SupportView = Backbone.View.extend(supportViewProto);

  module.exports = SupportView;
