  var _ = require('underscore');
  
  var eventHandler = function(options) {
    var _this = this;
    optKeys = _.keys(options);
    optKeys.forEach(function(optKey) {
      _this[optKey] = options[optKey];
    });
  };

  _.extend(eventHandler.prototype, { 

   initialize: function(eventSource, router) {
    this.eventSource = eventSource;
    this.router = router;
    this._bindEvents();
   },

    navigate: function(location, options) {
      this.router.navigate(location, options);
    },  

    _bind: function(evnt, callback) {
      this.eventSource.bind(evnt, callback.bind(this));    
    },
 
    _bindEvents: function() {
      var _this = this;
      if (!_this.events) return;
      var events = _.keys(_this.events);
      events.forEach(function(evnt) {
        _this._bind(evnt, _this[_this.events[evnt]]);
      }); 
    }
  });
  
  module.exports = eventHandler;
