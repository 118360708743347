  var Backbone = require('backbone'),
      _ = require('underscore'),
      $ = require('jquery'),
      Promise = require('bluebird'),
      appConfig = require('../config/config.js'),
      baseModel = require('./base.js'),
      appEvents = require('../appEvents.js');

  var appUserProto = _.assign({
    clear: function(options) {
      // Wipe JWT from global config
      appConfig.token = null; 
      
      // Clear all attributes excluding the non-db ones
      var attrs = {};
      var keepEmptyAttrs = ['customer', 'businessUnits']; 
      
      for (var key in this.attributes) {
        if (!_.contains(keepEmptyAttrs, key)) { 
          attrs[key] = void 0;
        }
      }
      return this.set(attrs, _.extend({}, options, {unset: true}));
    },

    url: function() {
      return this.urlRoot + "/users/" + this.get(this.idAttribute); 
    }, 
    
    init: function(options) {
      _.bindAll(this, "fetch");
      appEvents.bind("loggedIn", this.fetch);
      this.on('error', this.userFailed, this);
      this.on('change:id', this.setToken, this);
    },

    /* This is triggered if appUser can't be retrieved  */
    userFailed: function() {
      appEvents.trigger("userFailed");
    },

    setToken: function() {
      appConfig.token = this.get("jwt");
      this.trigger('token');
    },
    
    /* Logs user out

    */
	logout: function(onSuccess, onFailure) {
	  var self = this;
	  $.ajax({
		method: 'DELETE',
		url: self.urlRoot + '/users/' + self.id + '/tokens?token = ' + appConfig.token,
		headers: { 'x-access-token': appConfig.token },
		success: function(data, statusText, jqXHR) {
			onSuccess(data, statusText, jqXHR);
		},
		error: function(error, statusText, jqXHR) {
		    onFailure(error);
		}
	  });
	}
  }, baseModel);

  var appUser = Backbone.Model.extend(appUserProto,
   /* Class Methods

  */
  {
	/*  Logs a user in

    */
    authenticate: function(email, password) {
	  return new Promise(function(resolve, reject) {
		var body = { email: email, password: password };
		$.ajax({
			method: 'POST',
			url: appConfig.apiUrl + "/auth",
			data: JSON.stringify(body),
			success: function(data, statusText, jqXHR) {
			  resolve(data);
			},
			error: function(error, statusText, jqXHR) {
			  reject(error.responseJSON);
			},
			processData: false,
			dataType: 'json',
			contentType: 'application/json'
		});
	  });
	}
  });

  module.exports = new appUser();
