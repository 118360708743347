  var Backbone = require('backbone'),
      $ = require('jquery'),
      _ = require('underscore'),
      baseModel = require('./base.js');

  var accountProto = _.assign({
    idAttribute: "code",
   
    url: function() { 
      var base = this.urlRoot + '/accounts';
      if (this.id) {
        base += '/' + this.id;
      } 
      return base;
    },

    resetPassword: function(onSuccess, onError, sms) {
      var self = this;
      var url = self.urlRoot + '/accounts/' + self.get("email") + '/password';

      if (sms) {
        url = url + "?sms=true";
      }

	  $.ajax({
		method: 'POST',
		url: url,
		headers: { 'x-access-token': appConfig.token },
		success: function(data, statusText, jqXHR) {
		  onSuccess(data);
		},
		error: function(error, statusText, jqXHR) {
	      onError(error);
		}
	  });
     
    },

    cancel: function(onSuccess, onError) {
      this.save({ status: 'deactivated' }, { error: onError, success: onSuccess, patch: true });
    },

    blacklist: function(onSuccess, onError) {
      this.save({ status: 'deleted', isBlacklisted: true }, { error: onError, success: onSuccess, patch: true });
    },

    deactivate: function(onSuccess, onError) {
      this.save({ status: 'deactivated' }, { error: onError, success: onSuccess, patch: true });
    },
 
    reactivate: function(onSuccess, onError) {
      this.save({ status: 'trial' }, { error: onError, success: onSuccess, patch: true });
    },

    setAlternateEmail: function(email, onSuccess, onError) {
      this.save({ alternateEmail: email }, { error: onError, success: onSuccess, patch: true });
    },

    setAlternatePhone: function(phone, onSuccess, onError) {
      this.save({ alternatePhone: phone }, { error: onError, success: onSuccess, patch: true });
    },


    init: function(attributes, options) {
      options || (options = {});
    },

    getIdentity: function() {
      return this.get("firstName") ?
               this.get("firstName") + ' ' + this.get("lastName") :
               this.get("email"); 
    },
    
  }, baseModel);

  var Account = Backbone.Model.extend(accountProto);

  module.exports = Account;
