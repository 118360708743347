  var app = require('./app.js'),
      $ = require('jquery'),
      MetronicApp = require('./lib/metronic'),
      MetronicLayout = require('./lib/metronic-layout'),
      bootstrap = require('bootstrap');

  $(document).ready(function() {    
     MetronicApp.init(); // init metronic core componets
	 MetronicLayout.init(); // init metronic core componets
  });

  app();
